import isAuthenticatedGuard from '@/router/authGuard'

export function isAuthenticatedEmpresaGuard( to, from, next ){
    isAuthenticatedGuard(to, from, async (authResult) => {
        if (authResult === undefined) {
            const empraisLogged = localStorage.getItem('empraisLogged')
            const ok = empraisLogged !== null
            if ( ok ){
                if(parseInt(empraisLogged) !== 0){
                    next()
                }
                else{
                    next({ name: 'inicio' })
                }
            }
            else{
                next({ name: 'inicio' })
            }
        }        
        else{
            next(authResult)
        }
    })
}

// export default isAuthenticatedEmpresaGuard