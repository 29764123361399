<template>
    <div class="row m2">
        <div class="col-xs-12 col-md-3 mb-3">
            <button @click="createEmprais" type="button" class="btn btn-outline-info">
                <i class="fas fa-plus"></i>
                Crear empresa
            </button>
        </div>
    </div>
    <div class="row m2">
        <div class="table-responsive" style="max-height: 400px !important">
            <table class="table table-bordered table-hover">
                <thead class="table-info sticky-top top-0">
                    <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Logo</th>
                        <th>Estatus</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody v-if="!showLoad">
                    <tr v-for="(emp, index) in empresas" :key="index">
                        <td>{{ emp.id_empresa }}</td>
                        <td>{{ emp.nombre_empresa }}</td>
                        <td>
                            <img v-if="emp.logo !== ''" :src="`data:image/png;base64,${emp.logo}`"
                                :alt="`Logo de ${emp.nombre_empresa}`" width="100" height="100" />
                            <input :id="`fileLogo${emp.id_empresa}`" type="file" ref="hasFileInput"
                                @change="upLoadLogo(emp.id_empresa)" accept="image/png, image/gif, image/jpeg"
                                style="display: none" />
                            <button type="button" class="btn btn-sm btn-warning ml-3" @click="subeLogo(emp.id_empresa)">
                                <span v-if="emp.logo !== ''">Actualizar logo</span>
                                <span v-else>Subir logo</span>
                            </button>
                        </td>
                        <td>{{ emp.estatus == 1 ? 'Activa' : 'Inactiva' }}</td>
                        <td>
                            <button type="button" class="btn btn-info mr-2" @click="editEmpresa(index)">
                                Editar
                            </button>
                            <button type="button" class="btn btn-outline-danger" @click="deleteEmpresa(emp.id_empresa)">
                                Eliminar
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="5">
                            <loader-nicodemus :msje="'Cargando lista de empresas, espere...'"></loader-nicodemus>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <modal-empresas :modalId="'modalEmprais'" ref="modalEmprais" :title="'SE Nicodemus'">
        <template v-slot:modalBody>
            <div v-if="!showLoad" class="row m-1">
                <div class="col-xs-12">
                    <div class="form-group">
                        <label for="inptNameEmp"><strong>Nombre de la empresa:</strong></label>
                        <input id="inptNameEmp" type="text" class="form-control input-sm" v-model="empresaNameEdit" />
                    </div>
                </div>
            </div>
            <div v-else class="row m-1">
                <div class="justify-content-center text-center w-100">
                    <i class="fas fa-circle-notch fa-2x fa-spin"></i><br />
                    <p>Realizando acción espere</p>
                </div>
            </div>
        </template>
        <template v-slot:fuderModal>
            <button v-if="!showLoad" @click="SaveEdit" type="button" class="btn btn-outline-success">
                <strong>Guardar</strong>
            </button>
            <button v-if="showCloseModal" @click="CloseEdit" type="button" class="btn btn-outline-danger">
                <strong>Cerrar</strong>
            </button>
        </template>
    </modal-empresas>

    <modal-empresas :modalId="'modalAddEmpresa'" ref="modalAddEmpresa" :title="'SE Nicodemus'">
        <template v-slot:modalBody>
            <div id="formulario" v-if="!showLoadModalAdd">
                <div class="row m-2">
                    <div class="col-xs-12 w-100">
                        <div class="form-group">
                            <label for="inptAddNameEmp"><strong>Nombre de la empresa:</strong></label>
                            <input v-model="formNameEmpresa" type="text" id="inptAddNameEmp" class="form-control" />
                        </div>
                    </div>
                </div>
                <div class="row m-2">
                    <div class="col-xs-12 col-sm-8 w-100">
                        <div class="form-group">
                            <input :id="`fileLogoAddEmprais`" type="file" ref="hasFileInputAdd"
                                @change="upLoadLogoAdd()" accept="image/png, image/gif, image/jpeg"
                                style="display: none" />
                            <label for="btnAddLogo"><strong>Logo de la empresa:</strong></label>
                            <button @click="subeLogoAdd" type="button" id="btnAddLogo" class="w-100 btn btn-info">
                                <span v-if="labelBtnAddLogo">Agregar logo</span>
                                <span v-else>Cambiar logo</span>
                            </button>
                        </div>
                    </div>
                    <div v-if="!labelBtnAddLogo" class="col-xs-12 col-sm-4 w-100 ml-2">
                        <img :src="`data:image/png;base64,${formNameLogo}`" alt="logo nueva empresa" width="70"
                            height="70" />
                    </div>
                </div>
            </div>
            <div id="cargando" v-else>
                <div class="justify-content-center text-center w-100">
                    <i class="fas fa-circle-notch fa-2x fa-spin"></i><br />
                    <p>Realizando acción espere</p>
                </div>
            </div>
        </template>
        <template v-slot:fuderModal>
            <div id="botones" v-if="!showLoadModalAdd">
                <button class="btn btn-danger mr-3" @click="closeModalAddEmpresa">
                    <strong>Cancelar</strong>
                </button>
                <button class="btn btn-primary" @click="saveEmprais">
                    <strong>Guardar</strong>
                </button>
            </div>
        </template>
    </modal-empresas>

    <modal-loading ref="modalLoading"></modal-loading>
</template>
<script>
import { storeToRefs } from "pinia";
import { useApStore } from "@/store/appStore";
import { useEmpresaStore } from "../store/empresaStore";
import { ref, inject, toRaw, computed } from "vue";
import { defineAsyncComponent } from "@vue/runtime-core";
import LoaderNicodemus from "@/components/global/components/LoaderNicodemus.vue";

export default {
    components: {
        ModalEmpresas: defineAsyncComponent(() =>
            import("@/modules/administracion/empresas/components/ModalEmpresas")
        ),
        ModalLoading: defineAsyncComponent(() => import("@/components/global/components/ModalLoading")),
        LoaderNicodemus
    },
    setup() {
        const store = useEmpresaStore();
        const { empresas } = storeToRefs(store);
        const modalEmprais = ref();
        const apStore = useApStore();
        apStore.titleContainer = "Administrar empresas";
        const showLoad = ref(false);
        const showCloseModal = ref(false);
        const Swalert = inject("Swalert");
        const hasFileInput = ref();

        const empresaNameEdit = ref("");
        const empraisEdit = ref(0);

        //Logo empresa UPDATE
        const file = ref(null);
        const fileName = computed(() => file.value?.name);
        const fileExtension = computed(() =>
            fileName.value?.substr(fileName.value?.lastIndexOf(".") + 1)
        );
        const fileMimeType = computed(() => file.value?.type);

        //Agregar empresa
        const modalAddEmpresa = ref()
        const formNameEmpresa = ref("")
        const formNameLogo = ref()
        const showLoadModalAdd = ref(false)
        //Logo empresa CRETAE
        const labelBtnAddLogo = ref(true);
        const fileAdd = ref(null);
        const fileNameAdd = computed(() => fileAdd.value?.name);
        const fileExtensionAdd = computed(() =>
            fileNameAdd.value?.substr(fileNameAdd.value?.lastIndexOf(".") + 1)
        );
        const fileMimeTypeAdd = computed(() => fileAdd.value?.type);
        const hasFileInputAdd = ref();

        //delete empresa
        const modalLoading = ref()
        const empresasLoad = ref(false)

        const obtieneEmpresas = async () => {
            empresasLoad.value = true
            const { ok, message } = await store.getEmpresas();
            if (ok) {
                empresasLoad.value = false
            } else {
                console.error("ERROR obteniendo las empresas", message);
            }
        };

        const editEmpresa = (index) => {
            empresaNameEdit.value = empresas.value[index].nombre_empresa;
            modalEmprais.value.openModal();
            empraisEdit.value = index;
        };

        const subeLogo = (idEmpresa) => {
            const inputFileLogo = document.getElementById(`fileLogo${idEmpresa}`);
            inputFileLogo.click();
        };

        const subeLogoAdd = () => {
            const inputFileLogo = document.getElementById(`fileLogoAddEmprais`);
            inputFileLogo.click();
        };

        const upLoadLogo = async (idEmpresa) => {
            let tr = document.getElementById(`fileLogo${idEmpresa}`);
            console.log("inputFile", tr);
            file.value = await event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file.value);
            reader.onload = async () => {
                console.log("file onload");
                const encodedFile = reader.result.split(",")[1];
                console.log(encodedFile);
                empresas.value.forEach(async (emp, index) => {
                    if (emp.id_empresa === idEmpresa) {
                        emp.logo = encodedFile;
                        console.log(`emp[${index}]`);
                        await updateLogoApi(toRaw(emp));
                    }
                });
            };
            reader.onerror = function (error) {
                console.log("file onload");
                console.error("ERROR PLANTILLA: ", error);
            };
            if (tr == 99) {
                Swalert("mamaste pájaro");
                console.log(fileExtension.value);
                console.log(fileMimeType.value);
            }
        };

        const upLoadLogoAdd = async () => {
            let tr = document.getElementById(`fileLogoAddEmprais`);
            console.log("inputFile", tr);
            fileAdd.value = await event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(fileAdd.value);
            reader.onload = async () => {
                const encodedFile = reader.result.split(",")[1];
                formNameLogo.value = encodedFile;
                labelBtnAddLogo.value = false;
            };
            reader.onerror = function (error) {
                console.log("file onload ERROR");
                console.error("ERROR PLANTILLA: ", error);
            };
            if (tr == 99) {
                Swalert("mamaste pájaro");
                console.log(fileExtensionAdd.value);
                console.log(fileMimeTypeAdd.value);
            }
        };

        const updateLogoApi = async (empresa) => {
            showLoad.value = true;
            modalEmprais.value.openModal();
            const { ok, message } = await store.updateLogo(empresa);
            modalEmprais.value.closeModal();
            if (ok) {
                Swalert("Logo actualizado con éxito", "success");
            } else {
                Swalert(message, "error");
            }
            showLoad.value = false;
        };

        const SaveEdit = async () => {
            showCloseModal.value = false;
            const originalName = empresas.value[empraisEdit.value].nombre_empresa;
            if (originalName !== empresaNameEdit.value) {
                empresas.value[empraisEdit.value].nombre_empresa =
                    empresaNameEdit.value;
                showLoad.value = true;
                const { ok, message } = await store.saveChanges(
                    empresas.value[empraisEdit.value]
                );
                if (ok) {
                    modalEmprais.value.closeModal();
                    Swalert("Los cambios de guardaron correctamente", "success");
                } else {
                    Swalert(message, "error", () => {
                        empresas.value[empraisEdit.value].nombre_empresa = originalName;
                        showLoad.value = false;
                        showCloseModal.value = true;
                    });
                }
            } else {
                modalEmprais.value.closeModal();
                Swalert(
                    "No se detectaron cambios en el nombre de la empresa",
                    "warning"
                );
            }
        };

        const CloseEdit = () => {
            modalEmprais.value.closeModal();
            showCloseModal.value = false;
        };

        //Crear empresa
        const createEmprais = () => {
            modalAddEmpresa.value.openModal();
        };

        const closeModalAddEmpresa = () => {
            resetFormAddEmprais()
        };

        const resetFormAddEmprais = () => {
            modalAddEmpresa.value.closeModal();
            labelBtnAddLogo.value = true;
            formNameEmpresa.value = "";
            formNameLogo.value = "";
        }

        const saveEmprais = async () => {
            const valida = validaFormAddEmprais();
            if (valida.errors) {
                Swalert(valida.msje, "error");
            } else {
                showLoadModalAdd.value = true
                const empresa = {
                    nombre_empresa: formNameEmpresa.value,
                    logo: formNameLogo.value,
                    estatus: 1
                }
                const { ok, message } = await store.createEmpresa(empresa)
                if (ok) {
                    Swalert(`Empresa ${empresa.nombre_empresa} creada con éxito`, 'success', async () => {
                        modalAddEmpresa.value.closeModal()
                        showLoadModalAdd.value = false
                        resetFormAddEmprais()
                        await obtieneEmpresas()
                    })
                }
                else {
                    showLoadModalAdd.value = false
                    Swalert(`Ocurrió un error al crear la empresa: ${message}`, 'error')
                }
            }
        };

        const validaFormAddEmprais = () => {
            let respuesta = {
                errors: false,
                msje: "",
            };
            if (formNameEmpresa.value.trim().length < 5) {
                respuesta.errors = true;
                respuesta.msje += "*Debe proporcionar el nombre de la empresa";
            }

            return respuesta;
        };

        const deleteEmpresa = (idEmpresa) => {
            const warningParams = {
                totalBotones: 2,
                mostrarBotonCancelar: true,
                textoBtnConfirm: '¡Sí, eliminar!',
                textoBtnCancelar: '¡NO!, cancelar',
                backdrop: true
            }
            console.log('warningParams', warningParams);
            Swalert(`Se eliminará la empresa, esta acción no se puede deshacer, ¿Desea continuar?`,
                'warning',
                async () => {
                    modalLoading.value.openModal()
                    const { ok, message } = await store.deleteEmpresa(idEmpresa)
                    setTimeout(() => { modalLoading.value.closeModal() }, 777)
                    if (ok) {
                        Swalert('Empresa eliminada correctamente', 'success', async () => {
                            await obtieneEmpresas()
                        })
                    } else {
                        Swalert(`Ocurrió un error al eliminar la empresa: ${message}`, 'error')
                    }
                },
                warningParams
            )
        }

        obtieneEmpresas();

        return {
            hasFileInput,
            editEmpresa,
            subeLogo,
            upLoadLogo,
            empresas,
            modalEmprais,
            empresaNameEdit,
            SaveEdit,
            showLoad,
            showCloseModal,
            CloseEdit,
            modalAddEmpresa,
            createEmprais,
            //Create empresa
            hasFileInputAdd,
            formNameEmpresa,
            formNameLogo,
            labelBtnAddLogo,
            subeLogoAdd,
            upLoadLogoAdd,
            closeModalAddEmpresa,
            saveEmprais,
            showLoadModalAdd,
            deleteEmpresa,
            modalLoading,
            empresasLoad
        };
    },
};
</script>

<style>
.select2-selection__rendered {
    line-height: 33px !important;
}

.select2-container .select2-selection--single {
    height: 38px !important;
}

.select2-selection__arrow {
    height: 38px !important;
}
</style>