import { defineStore } from 'pinia'

export const useApStore = defineStore({
    id: 'apStore',
    state: () =>({
        titleContainer: '',
        select2Options: {
            // theme: 'bootstrap',
            // width: '100%',
            // height: '100%',
            // dropdownAutoWidth: true,
            "language": {
                "noResults": () => `<i class="far fa-sad-tear fa-2x"></i> No encontramos resultados.`
            },
            // escapeMarkup: function (markup) {
            //     return markup;
            // }
        }
    })
})