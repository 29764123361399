import { defineStore } from 'pinia'
import seNicodemusApi from '@/api/seNicodemusApi'

const usuariosBasePath = '/usuarios/'

export const useUserStore = defineStore({
    id: 'userStore',
    state: () =>({
        usuarios: []
    }),
    actions:{
        async getUsuarios(){
            try{
                const {data} = await seNicodemusApi.get('/usuarios')
                this.usuarios = data
                return {ok: true, message: ''}
            }
            catch(error){
                const codigo = error.response.status
                const msje = codigo === 401 ? `Error en las credenciales, verifique el usuario y/o la contraseña`: 
                `Ocurrió un error interno en el servidor, intenta más tarde: [Error]: ${error}`
                return {ok: false, message: msje}
            }
        },
        async deactivateUser(idUser){
            try{
                const {data} = await seNicodemusApi.post(`${usuariosBasePath}deactivate/${idUser}`)
                this.usuarios = data
                return {ok: true, message: ''}
            }
            catch(error){
                const codigo = error.response.status
                const msje = codigo === 401 ? `Error en las credenciales, verifique el usuario y/o la contraseña`: 
                `Ocurrió un error interno en el servidor, intenta más tarde: [Error]: ${error}`
                return {ok: false, message: msje}
            }
        }
    }
})