import { defineStore } from 'pinia'
import seNicodemusApi from '@/api/seNicodemusApi'

const empresasBasePath = '/empresas/'

export const useEmpresaStore = defineStore({
    id: 'empresaStore',
    state: () =>({
        empresas: [],
    }),
    actions:{
        async getEmpresas(){
            try{
                const {data} = await seNicodemusApi.get('/empresas')
                this.empresas = data
                return {ok: true, message: ''}
            }
            catch(error){
                const codigo = error.response.status
                const msje = codigo === 401 ? `Error en las credenciales, verifique el usuario y/o la contraseña`: 
                `Ocurrió un error interno en el servidor, intenta más tarde: [Error]: ${error}`
                return {ok: false, message: msje}
            }
        },
        async updateLogo(empresa){
            try{
                console.log('process.env.VUE_APP_API', process.env.VUE_APP_API)                
                const {data} = await seNicodemusApi.post(`${empresasBasePath}editLogo`, {empresa})
                console.log('EDIT LOGO API', data)
                return {ok: true, message: ''}
            }
            catch(error){
                const codigo = error.response.status
                const msje = codigo === 401 ? `Error en las credenciales, verifique el usuario y/o la contraseña e inicie sesión nuevamente`: 
                `Ocurrió un error interno en el servidor, intenta más tarde:<br>[Error]: ${error}`
                return {ok: false, message: msje}
            }
        },
        async saveChanges(empresa){
            try{
                const {data} = await seNicodemusApi.post(`${empresasBasePath}update`, {empresa})
                console.log('UPDATE EMPRESA', data)
                return {ok: true, message: ''}
            }
            catch(error){
                const codigo = error.response.status
                const msje = codigo === 401 ? `Error en las credenciales, verifique el usuario y/o la contraseña e inicie sesión nuevamente`: 
                `Ocurrió un error interno en el servidor, intenta más tarde:<br>[Error]: ${error}`
                return {ok: false, message: msje}
            }            
        },
        async createEmpresa(empresa){
            try{
                const {data} = await seNicodemusApi.post(`${empresasBasePath}create`, {empresa})
                console.log('CREATE EMPRESA', data)
                return {ok: true, message: ''}
            }
            catch(error){
                const codigo = error.response.status
                const msje = codigo === 401 ? `Error en las credenciales, verifique el usuario y/o la contraseña e inicie sesión nuevamente`: 
                `Ocurrió un error interno en el servidor, intenta más tarde:<br>[Error]: ${error}`
                return {ok: false, message: msje}
            }
        },
        async deleteEmpresa(idEmpresa){
            try{
                const {data} = await seNicodemusApi.post(`${empresasBasePath}delete/${idEmpresa}`)
                console.log('DELETE EMPRESA', data)
                return {ok: true, message: ''}
            }
            catch(error){
                const codigo = error.response.status
                const msje = codigo === 401 ? `Error en las credenciales, verifique el usuario y/o la contraseña e inicie sesión nuevamente`: 
                `Ocurrió un error interno en el servidor, intenta más tarde:<br>[Error]: ${error}`
                return {ok: false, message: msje}
            }
        },
        async getEmpresasLogin(){
            try{
                const {data} = await seNicodemusApi.get(`${empresasBasePath}login`)
                this.empresas = data
                return {ok: true, message: ''}
            }
            catch(error){
                const codigo = error.response.status
                const msje = codigo === 401 ? `Error en las credenciales, verifique el usuario y/o la contraseña`: 
                `Ocurrió un error interno en el servidor, intenta más tarde: [Error]: ${error}`
                return {ok: false, message: msje}
            }
        }, 
    }
})