<template>
    <div class="row m-1">
        <div class="table-responsive" style="max-height: 500px !important">
            <table class="table table-bordered table-hove table-striped">
                <thead class="table-info sticky-top top-0">
                    <tr>
                        <th>Usuario</th>
                        <th>Rol</th>
                        <th>Estatus</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody v-if="usersLoaded">
                    <tr v-for="usr in usuarios" :key="usr.id_usuario">
                        <td>{{ usr.usuario }}</td>
                        <td>{{ usr.nombre_rol }}</td>
                        <td>{{ usr.estatus === 1 ? 'Activo' : 'Inactivo' }}</td>
                        <td>
                            <button v-if="usr.estatus === 1" type="button" class="btn btn-sm btn-outline-danger" @click="desactivaUser(usr.id_usuario, usr.estatus)">
                                <strong>Desactivar </strong> <i class="fas fa-user-minus"></i>
                            </button>
                            <button v-else type="button" class="btn btn-sm btn-outline-success" @click="desactivaUser(usr.id_usuario, usr.estatus)">
                                <strong>Activar </strong> <i class="fas fa-user-plus"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="4" style="text-align: center !important;">
                            <span class="justify-content-center text-center">
                                <img src="@/assets/images/loader1.gif" alt="cargando" width="60" height="60"/>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" style="text-align: center !important;">
                            <strong>Cargando lista de usuarios, espere</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <modal-loading ref="modalLoading"></modal-loading>
    <modal-empresas :modalId="'modalEmpleado'" ref="modalEmpleado"></modal-empresas>
</template>

<script>
import { useUserStore } from '@/modules/administracion/usuarios/store/userStore'
import { storeToRefs } from "pinia"
import { useApStore } from '@/store/appStore'
import { defineAsyncComponent } from '@vue/runtime-core'
import { ref, inject } from 'vue'

export default {
    components: {
        ModalLoading: defineAsyncComponent(() => import("@/components/global/components/ModalLoading"))
    },
    setup() {
        const Swalert = inject("Swalert")
        const store = useUserStore()
        const { usuarios } = storeToRefs(store)
        const modalLoading = ref()
        const appStore = useApStore()
        appStore.titleContainer = 'Administrar usuarios'
        const usersLoaded = ref(false)
        const modalEmpleado = ref()

        const getUsers = async () => {
            const { ok, message } = await store.getUsuarios()
            if (ok) {
                usersLoaded.value = true
                console.log(ok);
            } else {
                console.error(`Error al obtener lista de empleados`, message)
            }
        }
        getUsers()

        const desactivaUser = async (idUser, estatus) => {
            const txtBtnConf = estatus === 1 ? 'desactivar' : 'activar'
            const warningParams = {
                totalBotones: 2,
                mostrarBotonCancelar: true,
                textoBtnConfirm: `¡Sí, ${txtBtnConf}!`,
                textoBtnCancelar: '¡NO!, cancelar',
                backdrop: true
            }
            Swalert(`¿Está seguro que desea ${txtBtnConf} a este usuario?`, 'warning', async() =>{
                await deactivateUser(idUser)
            }, warningParams)
        }        

        const deactivateUser = async (idUser) => {
            modalLoading.value.openModal()
            const {ok, message} = await store.deactivateUser(idUser)
            setTimeout(()=> {
                modalLoading.value.closeModal()
            }, 455)
            if(ok){
                Swalert('Operación realizada con éxito', 'success', async () =>{
                    await getUsers()
                })
            }
            else{
                Swalert(message, 'error')
            }
        }

        return {
            store,
            modalLoading,
            usuarios,
            usersLoaded,
            desactivaUser,
            modalEmpleado
        }
    },
}
</script>

<style></style>