import {useAuthStore} from '@/store/authStore'

const isAdminGuard = async( to, from, next ) => {
    const {ok} = await useAuthStore().isAdmin()
    if ( ok ){
        next()
    }
    else{
        next(from)
    }
}

export default isAdminGuard