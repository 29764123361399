import Swal from "sweetalert2"
import validator from "validator"

const animacionEntrada = {
    popup: 'animate__animated animate__heartBeat'
}

const animacionSalida = {
    popup: 'animate__animated animate__flipOutX'
}

const extend = function () {
	// Variables
	let extended = {}
	let deep = false
	let i = 0
	let length = arguments.length

	// Check if a deep merge
	if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
		deep = arguments[0]
		i++
	}

	// Merge the object into the extended object
	const merge = function (obj) {
		for ( let prop in obj ) {
			if ( Object.hasOwn( obj, prop ) ) {
				// If deep merge and property is an object, merge properties
				if ( deep && Object.prototype.toString.call(obj[prop]) === '[object Object]' ) {
					extended[prop] = extend( true, extended[prop], obj[prop] )
				} else {
					extended[prop] = obj[prop]
				}
			}
		}
	}

	// Loop through each object and conduct a merge
	for ( ; i < length; i++ ) {
		let obj = arguments[i]
		merge(obj)
	}
	return extended
}

const Swalert = (
    texto, tipoSwal = 'success', funcion = function () { }, warningParams = {
        totalBotones: 1,
        mostrarBotonCancelar: false,
        textoBtnConfirm: 'Sí, aceptar',
        textoBtnCancelar: 'No, cancelar',
        backdrop: true
    }, withTimer = false, timer = 1000
) => {
    let propsWarning = {
        showCancelButton: false,
        backdrop: true,
    }
    if (tipoSwal === 'warning' && warningParams.totalBotones === 2) {
        propsWarning.confirmButtonText = warningParams.textoBtnConfirm
        propsWarning.cancelButtonText = warningParams.textoBtnCancelar
        propsWarning.showCancelButton = warningParams.mostrarBotonCancelar
    }
    let tipoSwAl = ""
    let tituloSwal = ""
    switch (tipoSwal) {
        case 'success':
            tipoSwAl = tipoSwal
            tituloSwal = "Realizado!"
            break;
        case 'error':
            tipoSwAl = tipoSwal;
            tituloSwal = "Error!"
            break;
        case 'warning':
            tipoSwAl = tipoSwal;
            tituloSwal = "Atención!"
            break;
        default: tipoSwAl = 'error'
            tituloSwal = 'Error!'
            break;
    }
    let swalOptions = {
        title: tituloSwal,
        html: texto,
        icon: tipoSwAl,
        showClass: animacionEntrada,
        hideClass: animacionSalida,
        allowOutsideClick: false,
        confirmButtonColor: '#0069D9',
    }
    if (withTimer) {
        swalOptions.timer = timer;
        swalOptions.timerProgressBar = true;
    }
    // let opciones = $.extend({}, swalOptions, propsWarning)
    let opciones = extend(swalOptions, propsWarning);
    Swal.fire(opciones).then((result) => {
        if (result.isConfirmed) {
            funcion()
        }
    })
}

const transformToSelect2 = (object, idElement = 'id', texto = 'text') => {
    let array = []
    if(object !== null || object !== undefined){
        if(Object.keys(object).length > 0){
            for(let objeto of object){
                let item = {id: '', text: ''}
                if(idElement in objeto){
                    item.id = `${objeto[idElement]}`
                }
                if(texto in objeto){
                    item.text = objeto[texto]
                }
                array.push(item);
            }
        }
    }
    
    return array
}

const formateaFechaSinHora = (fechax) => {
    let fecha = `${fechax}`;
    let splitFecha = fecha.split("-");
    let mesInt = parseInt(splitFecha[1]);
    let mesString = '';
    switch (mesInt) {
        case 1: mesString = 'Enero'; break;
        case 2: mesString = 'Febrero'; break;
        case 3: mesString = 'Marzo'; break;
        case 4: mesString = 'Abril'; break;
        case 5: mesString = 'Mayo'; break;
        case 6: mesString = 'Junio'; break;
        case 7: mesString = 'Julio'; break;
        case 8: mesString = 'Agosto'; break;
        case 9: mesString = 'Septiembre'; break;
        case 10: mesString = 'Octubre'; break;
        case 11: mesString = 'Noviembre'; break;
        case 12: mesString = 'Diciembre'; break;
    }

    return `${parseInt(splitFecha[2])} de ${mesString} de ${splitFecha[0]}`;
}

const arrayBufferToBase64 = (buffer) => {
    // Convert ArrayBuffer to Uint8Array
    let binary = '';
    try{
        const bytes = new Uint8Array(buffer);

        // Convert each byte to a binary string
        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
    }
    catch(error){
        console.log('ERROR buffer to base64', error)
    }
    // Convert binary string to Base64
    return btoa(binary);
}

const formatDate = (d) => {
    const fecha = new Date(d)
    const dia = fecha.getDate().toString().padStart(2, '0')
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0') // Add leading zero
    const anio = fecha.getFullYear()
    // Format the date without time
    return `${anio}-${mes}-${dia}`
}

const formatTime = (hours, minutes, seconds = 0) => {
    const horas = `${hours}`.padStart(2, '0')
    const minutos = `${minutes}`.padStart(2, '0')
    const segundos = `${seconds}`.padStart(2, '0')

    return `${horas}:${minutos}:${segundos}`
}

const addDaysToDate = (date, days = 1) => {
    let result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
}

const emailValido = (email) => {
    return validator.isEmail(email)
}

//Función para validar un RFC
// Devuelve el RFC sin espacios ni guiones si es correcto
// Devuelve false si es inválido
// (debe estar en mayúsculas, guiones y espacios intermedios opcionales)
const validaRFC = (rfc, aceptarGenerico = true) => {
    const re       = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    var   validado = rfc.match(re);

    if (!validado)  //Coincide con el formato general del regex?
        return false;

    //Separar el dígito verificador del resto del RFC
    const digitoVerificador = validado.pop(),
          rfcSinDigito      = validado.slice(1).join(''),
          len               = rfcSinDigito.length,

    //Obtener el digito esperado
          diccionario       = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
          indice            = len + 1;
    var   suma,
          digitoEsperado;

    if (len == 12) suma = 0
    else suma = 481; //Ajuste para persona moral

    for(var i=0; i<len; i++)
        suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
    digitoEsperado = 11 - suma % 11;
    if (digitoEsperado == 11) digitoEsperado = 0;
    else if (digitoEsperado == 10) digitoEsperado = "A";

    //El dígito verificador coincide con el esperado?
    // o es un RFC Genérico (ventas a público general)?
    if ((digitoVerificador != digitoEsperado)
     && (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000"))
        return false;
    else if (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000")
        return false;
    return rfcSinDigito + digitoVerificador;
}

//Función para validar una CURP
const validaCURP = (curp) => {
    var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
    validado = curp.match(re);
	
    if (!validado){//Coincide con el formato general?
        return false;
    }
    //Validar que coincida el dígito verificador
    function digitoVerificador(curp17) {
        //Fuente https://consultas.curp.gob.mx/CurpSP/
        var diccionario  = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
            lngSuma      = 0.0,
            lngDigito    = 0.0;
        for(var i=0; i<17; i++)
            lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
        lngDigito = 10 - lngSuma % 10;
        if (lngDigito == 10) return 0;
        return lngDigito;
    }
  
    if (validado[2] != digitoVerificador(validado[1]))
        return false;
        
    return true; //Validado
}

// Devuelve un booleano si es un NSS válido
// (deben ser 11 dígitos sin otro caracter en el medio)
const validaNSS = (nss) => {
    const re       = /^(\d{2})(\d{2})(\d{2})\d{5}$/,
          validado = nss.match(re);
        
    if (!validado)  // 11 dígitos y subdelegación válida?
        return false;
        
    const subDeleg = parseInt(validado[1],10),
          anno     = new Date().getFullYear() % 100;
    var   annoAlta = parseInt(validado[2],10),
          annoNac  = parseInt(validado[3],10);
    
    //Comparar años (excepto que no tenga año de nacimiento)
    if (subDeleg != 97) {
        if (annoAlta <= anno) annoAlta += 100;
        if (annoNac  <= anno) annoNac  += 100;
        if (annoNac  >  annoAlta)
            return false; // Err: se dio de alta antes de nacer!
    }
    
    return luhn(nss);
}

// Algoritmo de Luhn
//  https://es.wikipedia.org/wiki/Algoritmo_de_Luhn
const luhn = (nss) => {
    var suma   = 0,
        par    = false,
        digito;
    
    for (var i = nss.length - 1; i >= 0; i--) {
        digito = parseInt(nss.charAt(i),10);
        if(par)
            if ((digito *= 2) > 9)
                digito -= 9;
        
        par = !par;
        suma += digito;
    }
    return (suma % 10) == 0;
}

export default {
    Swalert,
    transformToSelect2,
    formateaFechaSinHora,
    arrayBufferToBase64,
    formatDate,
    formatTime,
    addDaysToDate,
    emailValido,
    validaRFC,
    validaCURP,
    validaNSS
}