import isAdminGuard from '@/modules/administracion/router/adminGuard'
import EmpresasHomeComponent from '@/modules/administracion/empresas/components/EmpresasHomeComponent.vue'
import AdminUsuariosComponent from '@/modules/administracion/usuarios/components/AdminUsuariosComponent.vue'

export default [
    {
        path: '/admin-empresas',
        name: 'admin-empresas',
        beforeEnter: [ isAdminGuard ],
        component: EmpresasHomeComponent
    },
    {
        path: '/admin-usuarios',
        name: 'admin-usuarios',
        beforeEnter: [ isAdminGuard ],
        component: AdminUsuariosComponent
    },
    {
        path: '/admin-empleados',
        name: 'admin-empleados',
        beforeEnter: [ isAdminGuard ],
        component: () => import('@/modules/administracion/empleados/components/AdminEmpleadosComponent')
    },
    {
        path: '/admin-capacitaciones',
        name: 'admin-capacitaciones',
        beforeEnter: [ isAdminGuard ],
        component: () => import('@/modules/administracion/capacitaciones/components/AdminCapacitacionesComponent')
    },
    {
        path: '/admin-inducciones',
        name: 'admin-inducciones',
        beforeEnter: [ isAdminGuard ],
        component: () => import('@/modules/administracion/inducciones/components/AdminInduccionesComponent')
    },
    {
        path: '/admin-documentos-empleado',
        name: 'admin-documentos-empleado',
        beforeEnter: [ isAdminGuard ],
        component: () => import('@/modules/administracion/documentosEmpleado/components/AdminDocumentosEmpleadoComponent')
    },
    {
        path: '/admin-jefes',
        name: 'admin-jefes',
        beforeEnter: [ isAdminGuard ],
        component: () => import('@/modules/administracion/jefes/components/AdminJefesComponent')
    },
    {
        path: '/admin-puestos',
        name: 'admin-puestos',
        beforeEnter: [ isAdminGuard ],
        component: () => import('@/modules/administracion/puestos/components/AdminPuestosComponent')
    },
]