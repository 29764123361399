import { createRouter, createWebHistory } from 'vue-router'
import isAuthenticatedGuard from '@/router/authGuard'
import {isAuthenticatedEmpresaGuard} from '@/router/authEmpresaGuard'
import isLoggedInGuard from '@/modules/login/router/isLoggedInGuard'
import ModulesRouter from '@/modules/router'
import AdminRouter from '@/modules/administracion/router'

const routes = [
    {
        path: "/",
        name: "",
        beforeEnter: [isAuthenticatedEmpresaGuard],
        redirect: 'home',
        component: () => import(/* webpackChunkName: "Dashboard" */'@/components/DashboardComponent'),
        children: [
            ...ModulesRouter,
            ...AdminRouter,
        ]
    },
    {
        path: '/inicio',
        name: 'inicio',
        beforeEnter: [isAuthenticatedGuard],
        component: () => import(/* webpackChunkName: "EmpresasMenu" */ '@/modules/empresas-menu/components/EmpresasMenuComponent')
    },
    {
        path: "/login",
        name: "login",
        beforeEnter: [isLoggedInGuard],
        component: () => import(/* webpackChunkName: "LoginView" */ '@/modules/login/views/LoginView')
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/components/NotFoundPage')
    },
]



const router = createRouter({
    history: createWebHistory(),
    routes
})
export default router