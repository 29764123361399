<template>
    <span class="w-100" :id="dynamicID" style="text-align: center !important;">
        <p>
            <img src="@/assets/images/loader1.gif" alt="cargando" width="60" height="60" />
        </p>
        <p><strong>{{ msjeLoader }}</strong></p>
    </span>
</template>

<script>
import { toRefs, ref } from 'vue'

export default {
    props:{
        msje: {
            type: String,
            default: 'Cargando, espere...'
        }
    },
    setup(props) {
        const {msje} = toRefs(props)
        const msjeLoader = msje.value
        const dynamicID = ref('')

        const generateRandomString = (length) => {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        dynamicID.value = generateRandomString(19)

        return {
            msjeLoader,
            dynamicID
        }
    },
}
</script>